

html {
  font-size: 62.5%;
}

body {
  font: normal 500 20px / 1 'Archivo', Arial, Helvetica, sans-serif;
  color: $pri-color;
}

strong {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

h1 {
  font-size: 6.8rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 30px;
}

h2 {
  font-size: 4.8rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 50px;
}

h3 {
  font-size: 4.8rem;
  font-weight: bold;
  margin-bottom: 30px;
}

h4 {
  font-size: 2.4rem;
  line-height: 37px;
  font-weight: bold;
  margin-bottom: 30px;
}

h5 {
  font-size: 2.4rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 50px;
}

p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: 30px;
}

nav {
  font-size: 18px;
}