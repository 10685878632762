
.block-team {
  padding: 100px 50px;
  background: $fourth-color;

  p:last-child{
    margin-bottom: 0;
  }

  .heading {
    margin-top: 0;
  }

  a {
    text-decoration: underline;
  }
}