
.header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  background: $fifth-color;

  a {
    text-decoration: underline;
  }

  &-top {
    padding-top: 40px;
    margin-bottom: 100px;

    &-logo {
      position: absolute;
    }

    &-nav {
      &-item {
        margin-left: 35px;
      }
    }
  }

  &-banner {
    padding-bottom: 100px;

    p {
      font-weight: 500;
    }
  }

  .logo {
    height: 40px;
  }

}
