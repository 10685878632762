

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

.wrapper {
  overflow: hidden;
}

.font-sm {
  font-size: 14px;
}
