@media only screen and (max-width: 991px) {
  nav, .footer-top, .slider-arrow {
    display: none;
  }

  .header {
    padding-bottom: 30px;

    .header-top {
      margin-bottom: 30px;
    }

    .header-banner {
      padding-left: 10px;
      padding-right: 10px;
    }

    h1 {
      font-size: 4.2rem;
    }

    .btn.btn-pri {
      width: 100%;
      position: relative;
      text-align: center;

      .btn-icon {
        display: none;
      }
    }
  }

  .slider-item {
    max-width: 100%;
    padding: 50px 30px 50px;

    .slider-item-heading {
      font-size: 4.3rem;
    }

    .slider-item-subheading {
      display: block;
      font-size: 4.3rem;
    }

    .slider-item-link {
      font-size: 15px;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    display: none;
  }

  .card-list{
    padding: 25px 25px 0 25px;

    .col-xs-12 {
      margin-bottom: 25px;
    }
  }

  .footer {
    padding-bottom: 0;
    .footer-copyright {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .editor {
    .btn-icon {
      display: none;
    }
  }
}
