
.card {
  &-list {
    background-color: #E6EBEE;
    padding: 45px;
    border-radius: 5px;

    &-item {
      background-color: #fff;
      padding: 40px 30px 30px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;

      &:before {
        content: '';
        width: 8px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }

      &--green:before {
        background: linear-gradient(
            to bottom,
              $pri-color 25%,
              lighten($pri-color, 25%) 25%,
              lighten($pri-color, 25%) 50%,
              lighten($pri-color, 45%) 50%,
              lighten($pri-color, 45%) 75%,
              transparent 75%
          );
      }

      &--blue:before {
        background: linear-gradient(
            to bottom,
              $sec-color 25%,
              lighten($sec-color, 25%) 25%,
              lighten($sec-color, 25%) 50%,
              lighten($sec-color, 45%) 50%,
              lighten($sec-color, 45%) 75%,
              transparent 75%
          );
      }

      &-heading {
        color: #4A4A4A;
      }

      &-price {
        font-size: 48px;
        color: #4A4A4A;
        margin-top: 0;
        margin-bottom: 50px;

        &:before {
          content: attr(data-before);
          font-size: 24px;
          color: $pri-color;
          position: relative;
          top: -15px;
        }

        &:after {
          content: attr(data-after);
          font-size: 24px;
          color: $pri-color;
          margin-left: 3px;
        }
      }

      &-copy {
        min-height: 104px;
        color: #9B9B9B;
        margin-bottom: 50px;
      }
    }
  }
}
