.block-backers {
  padding-bottom: 100px;
  img {
    height: 100px;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}