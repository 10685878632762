.heading {
  margin-top: 100px;

  & span {
    border-bottom: 3px solid;
  }
}
.swiper-button-prev, .swiper-button-next {
  color: lighten($pri-color, 50%);

  &.swiper-button-disabled {
    color: $pri-color;
  }
}