
.background {
  &-pri { background-color: $pri-color; }
  &-white { background-color: #ffffff; }
  &-off-white { background-color: #f1f1f1; }
  &-grey { background-color: #9B9B9B; }
  &-comment { background-color: #A5B3BB; }
  &-var { background-color: #6206DB; }
  &-function { background-color: #0677DB; }
  &-string { background-color: #00D89A; }
  &-indigo { background-color: #5E239D; }
}

.background-after {
  &-pri:after { background-color: $pri-color; }
  &-white:after { background-color: #ffffff; }
  &-off-white:after { background-color: #f1f1f1; }
  &-grey:after { background-color: #9B9B9B; }
  &-comment:after { background-color: #A5B3BB; }
  &-var:after { background-color: #6206DB; }
  &-function:after { background-color: #0677DB; }
  &-string:after { background-color: #00D89A; }
  &-indigo:after { background-color: #5E239D; }
  &-gradient {
    z-index: 0;
    top: 80px;
    opacity: 0.4;
  }
  &-gradient:after {
    background-image: -webkit-linear-gradient(left, #9B5DEF, #0677DB);
    background-image: -o-linear-gradient(left, #9B5DEF, #0677DB);
    background-image: linear-gradient(to right, #9B5DEF, #0677DB);
  }
}
