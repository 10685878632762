
.footer {
  color: #fff;
  margin-top: 50px;
  padding: 50px 0;
  background: $pri-color;

  img {
    height: 100%;
    max-height: 34px;
  }

  &-logo {
    margin-bottom: 20px;
  }
}
