.btn {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 16px;
  background-image: none; 
  padding: 13px 15px;
  border: 1px solid;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;

  &-pri {
    background-color: $pri-color;
    color: $sec-color; 
    border-color: $pri-color;
  }

  &-pri-w {
    background-color: $pri-color;
    color: #fff; 
    border-color: $pri-color;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
  }

  &-sec {
    background-color: #fff;
    color: #0677DB; 
    border-color: #fff;
  }

  &-sec-o {
    background-color: transparent;
    color: #fff;
    border-color: #fff; 
  }

  &-green {
    background-color: #0A9601;
    color: #fff;
    border-color: #0A9601; 
  }

  &-icon {
    padding-left: 20px;
  }
}