
.block-tools {
  position: relative;
  background-color: $pri-color;
  
  .container {
    position: absolute;
    color: white;
    z-index: 9999;
  }
}

.slider {
  font-size: 0;
  white-space: nowrap;
  color: #fff;
  border-radius: 5px;

  &-title {
    text-transform: uppercase;
  }

  &-item {
    display: inline-block;
    vertical-align: top;
    background-color: $pri-color;
    padding: 100px 60px;
    width: 90%;
    white-space: normal;

    &-subtitle {
      line-height: 58px;
    }

    &-copy {
      opacity: .87;
      font-size: 16px;
      line-height: 25px;
      min-height: 75px;
    }

    &-link {
      font-size: 12px;
      color: white;
    }

    &--dark {
      background-color: $sec-color;
    }
  }
}

.swiper-button-next, .swiper-button-prev {
  top: 10%;
  z-index: 9999;
}